/**
 * string, number 를 다루는 함수들
 */

import T from './index'

// 필요한경우 yarn add js-base64
// import { encode as base64encode, decode as base64decode } from 'js-base64';

export default {

  // base64encode, base64decode,

  /**
   * blank 여부<pre>
   * * TODO : string, number만 고려됨 (Object, Array에 대한 입력은 고려되지 않았다.)
   *   T.isBlank("")  === true
   *   T.isBlank(" ") === true
   *   T.isBlank("a") === false
   *   T.isBlank(1)   === false
   *   T.isBlank({})  === false // TODO Object 부분은 고민중..
   *   T.isBlank([])  === true  // TODO Array 부분은 고민중..
   * </pre>
   */
  isBlank: (str: any): boolean => T.isEmpty(_.trim(str)),

  /**
   * blank 아님 여부<pre>
   * * TODO : string, number만 고려됨 (Object, Array에 대한 입력은 고려되지 않았다.)
   *   T.isNotBlank("")  === false
   *   T.isNotBlank(" ") === false
   *   T.isNotBlank("a") === true
   *   T.isNotBlank(1)   === true
   *   T.isNotBlank({})  === true   // TODO Object 부분은 고민중..
   *   T.isNotBlank([])  === false  // TODO Array 부분은 고민중..
   * </pre>
   * @param str
   * @returns {boolean}
   */
  isNotBlank: str => !T.isBlank(str),

  /**
   * 모든 변수들이 Not Blank인가 ? <pre>
   *   T.isNotBlankAll("aa","bb") === true
   *   T.isNotBlankAll("aa","  ") === false
   * </pre>
   * @param {...string} str_list 문자열들
   */
  isNotBlankAll: (...str_list) => str_list.every(T.isNotBlank),

  /**
   * null 또는 undefined 일때 ''를 반환.
   * T.ifNull()하고 다른점은 이것은 무조건 String 를 반환한다.<pre>
   *   T.defaultString(null)      === ''
   *   T.defaultString(null, 'a') === 'a'     // 기본값을 'a'로 변경
   *   T.defaultString('123')     === '123'
   *   T.defaultString(123)       === '123'   // 숫자로 123 지정해도 문자로 123 나온다
   *   T.defaultString({})        === '[object Object]' // TODO : Object는 고려되지 않았다
   *   T.defaultString([1,2])     === '1,2'             // TODO : Array는 고려되지 않았다
   * </pre>
   * @param {string|number} str
   * @param {string} defaultStr (기본:'') 기본값을 변경할 수 있다
   * @returns {string}
   */
  defaultString: (str, defaultStr = '') => ""+T.ifNU(str, defaultStr),

  /**
   * 지정된 문자열이 word로 시작하는가 ?<pre>
   *   T.startsWith('abcd', 'ab') === true
   *   T.startsWith(1234, 12)     === true    // 입력값은 무조건 문자열로 변환 후 검사한다.
   * </pre>
   * @param {string} str
   * @param {string} word
   * @returns {boolean}
   */
  startsWith: (str, word) => T.defaultString(str).startsWith(word),

  /**
   * 지정된 문자열이 word로 끝나는가 ?<pre>
   *   T.endsWith('abcd', 'cd') === true
   *   T.endsWith(1234, 34)     === true    // 입력값은 무조건 문자열로 변환 후 검사한다.
   * </pre>
   * @param {string} str
   * @param {string} word
   * @returns {boolean}
   */
  endsWith: (str, word) => T.defaultString(str).endsWith(word),

  /**
   * 왼쪽부터 자르기<pre>
   *   T.left('1234', 2)  === '12'
   *   T.left('1234', 10) === '1234'  // 넘어가면 가능한 곳 까지만
   *   T.left(null, 2)    === ''      // empty 면 ""
   * </pre>
   * @param {string} str
   * @param {number} len
   * @returns {string}
   */
  left: (str, len) => T.isEmpty(str) ? "" : str.substring(0, len),

  /**
   * 오른쪽부터 자르기<pre>
   *   T.right('1234', 2)  === '34'
   *   T.right('1234', 10) === '1234' // 넘어가면 가능한 곳 까지만
   *   T.right(null, 2)    === ''     // empty 면 ""
   * </pre>
   * @param {string} str
   * @param {number} len
   * @returns {string}
   */
  right: (str, len) => T.isEmpty(str) ? "" : str.substring(str.length, str.length-len),

  /**
   * 지정위치에 문자열 추가. 지정위치가 허공이면 아무것도 안한다.<pre>
   *   T.insertStr('123456', 3, '-')  === '123-456'
   *   T.insertStr('123456', 10, '-') === '123456'
   *   T.insertStr(null, 10, '-')     === null
   * </pre>
   * @param {string} str
   * @param {number} index
   * @param {string} insertStr
   * @returns {string}
   */
  insertStr: (str, index, insertStr) => T.isEmpty(str) || str.length < index ? str
    : str.substring(0, index) + insertStr + str.substring(index),

  /**
   * 지정 자리에 하이픈(-) 넣기<pre>
   *   T.insertHyphen('123456', 3) === '123,456'
   * @param {string} str
   * @param {number} index
   * @returns {string}
   */
  insertHyphen: (str, index) => T.insertStr(str, '-', index),

  /**
   * 지정 자리에 콜론(:) 넣기<pre>
   *   T.insertColon('123456', 3) === '123:456'
   * @param {string} str
   * @param {number} index
   * @returns {string}
   */
  insertColon: (str, index) => T.insertStr(str, ':', index),

  /**
   * 좌우로 감싼다.<pre>
   *   T.wrap(null)                   === ''
   *   T.wrap("")                     === ''
   *   T.wrap(123, null, ' 원')       === '123 원'
   *   T.wrap("123", '(', ')')        === '(123)'
   *   T.wrap(0, null, ' 원')         === ''
   *   T.wrap(0, null, ' 원', true)   === '0 원'   // numZeroWrap 옵션 사용 ( 0도 있는걸로 취급 )
   * </pre>
   * @param v 값
   * @param left 왼쪽에 붙임
   * @param right 오른쪽에 붙임
   * @param numZeroWrap 숫자 0 을 wrap 할지 여부 ( 기본 false )
   */
  wrap: (left ?: string, v ?: string | number, right ?: string, numZeroWrap: boolean = false) => {
    if (T.isNU(v)) return ""
    const str = typeof v === 'string' ? _.trim(v) : ""
    if (str === '0' && numZeroWrap === false) return "" // numZeroWrap 옵션이 false 인데 v 가 0이면 ""
    if (str.length < 1) return ""

    return T.ifNU(left) + str + T.ifNU(right)
  },

  /**
   * 콤마 넣기<pre>
   *   T.comma()        === ""
   *   T.comma(null)    === ""
   *   T.comma("")      === ""
   *   T.comma(0)       === 0
   *   T.comma("0")     === 0
   *   T.comma(1234)    === 1,234
   *   T.comma(1234.56) === 1,234.56
   *   T.comma(-1234)   === -1,234
   *   * object, array는 고려되지 않음
   *   </pre>
   * @param {string|number} v
   * @returns {string}
   */
  comma: (v: string | number) => {
    if (T.isNU(v) || v === "") return ""
    let n = '' + v
    if (n === "0") return n // 숫자0하고 문자 "0" 하고
    const reg = /(^[+-]?\d+)(\d{3})/
    while (reg.test(n))
      n = n.replace(reg, '$1' + ',' + '$2')
    return n
  },

  /**
   * 값이 있을 경우 콤마를 넣고 뒤에 ' 원'을 붙인다.<pre>
   *   T.commaWon(null)      === ''
   *   T.commaWon(1234)      === '1,234 원'
   *   T.commaWon(1234,'$')  === '1,234$'
   * </pre>
   * @param n      금액
   * @param surfix 기본값 ' 원'
   * @param numZeroWrap 0원 표시 여부
   */
  commaWon: (n, surfix: string = ' 원', numZeroWrap: boolean = false) => {
    const v = numZeroWrap ? T.ifBlank(T.comma(n), "0") : T.comma(n)
    return T.wrap('', v, surfix, numZeroWrap)
  },

  /**
   * 콤마를 다 없앤다.<pre>
   *   T.removeComma("123,456") === "123456"
   *   T.removeComma(null)      === ""
   *   T.removeComma(1233)      === 오류발생!! TypeError
   *   T.removeComma({})        === 오류발생!! TypeError
   *   * object, array는 고려되지 않음
   *   </pre>
   * @param {string} str
   * @returns {string}
   */
  removeComma: str => T.isEmpty(str) ? "" : str.replace(/^\$|,/g, ""),

  /**
   * 하이픈(-)을 다 없앤다.<pre>
   *   T.removeHyphen("123-456") === "123456"
   *   T.removeHyphen(null)      === ""
   *   T.removeHyphen(1233)      === 오류발생!! TypeError
   *   T.removeHyphen({})        === 오류발생!! TypeError
   *   </pre>
   * @param str
   * @returns {string|*}
   */
  removeHyphen: str => T.isEmpty(str) ? "" : str.replace(/^\$|-/g, ""),

  /**
   * 숫자를 절대값으로 변환하고 3자리마다 콤마 찍는다<pre>
   *   T.absComma(-1234) === '1,234'  // 음수 사라짐
   *   </pre>
   */
  absComma: (value: number | string) =>
    T.isBlank(value) ? "" :
    typeof value === 'number' ? T.comma(Math.abs(value)) :
    typeof value === 'string' ? T.NF(Number(value), v => T.comma(Math.abs(v)), value) : value,

  substring: (str, st, ed) => {
    try {
      return str.substring(st, ed);
    } catch (e) {
      return '';
    }
  },

  /**
   * 왼쪽에 0을 채운다.<pre>
   *   T.lpad0("123", 5) = "00123"
   *   T.lpad0(null, 5)  = ""
   *   </pre>
   * @param {string} str
   * @param {number} len
   * @returns {string}
   */
  lpad0: (str, len) => _.padStart(str, len, '0'),

  /**
   * separator 기준으로 왼쪽거 가져온다.
   *   <pre>
   *   T.substringBefore(null, *)      = null
   *   T.substringBefore(undefined, *) = undefined
   *   T.substringBefore("", *)        = ""
   *   T.substringBefore("abc", "a")   = ""
   *   T.substringBefore("abcba", "b") = "a"
   *   T.substringBefore("abc", "c")   = "ab"
   *   T.substringBefore("abc", "d")   = "abc"
   *   T.substringBefore("abc", "")    = ""
   *   T.substringBefore("abc", null)  = "abc"
   *   T.substringBefore("abc", undefined)  = "abc"
   *   </pre>
   * @param {string} str
   * @param {string} word
   * @returns {string}
   */
  substringBefore: (str, word) => {
    if (T.isEmpty(str) || T.isNU(word)) return str
    if (T.isEmpty(word)) return ""
    const pos = str.indexOf(word)
    if (pos < 0) return str
    return str.substring(0, pos)
  },


  /**
   * 한글을 2자로 취급하는 길이체크<pre>
   * T.byteLen('한글12') === 6
   */
  byteLen: (str: string): number => {
    return !str ? 0 : ((s: string) => {
      let b = 0;
      for (let i = 0; i < s.length; i++) {
        const c = s.charCodeAt(i);
        b += c >> 11 ? 2 : c >> 7 ? 2 : 1;
      }
      return b;
    })(str);
  },

  byteLeft: (str, maxByte) => {
    let b, i, c
    for (b = i = 0; !isNaN(c=str.charCodeAt(i));) {
      b += c >> 11 ? 2 : c >> 7 ? 2 : 1
      if (b > maxByte)
        break;
      i++;
    }
    return str.substring(0,i);
  },

  /**
   * 입력값이 number 라면 그대로 반환.
   * string 이라면 number로 변환한다.
   * string이 아니거나 string이라도 변환에 실패한 경우 defaultVal(null)를 반환한다.<pre>
   *   T.toNumber(123)       === 123    // number 라고 그대로 반환
   *   T.toNumber(" 123 ")   === 123    // number 변환 성공
   *   T.toNumber("1 2 3")   === null   // 공백 있어서 실패
   *   T.toNumber({})        === null   // String 아님
   *   T.toNumber("123a", 0) === 0      // 기본값을 0 으로 지정
   * </pre>
   * @param obj
   * @param defaultVal (기본:null) 값을 구할 수 없을 경우 대체할 값. 무엇이든 가능
   * @returns 실패시 null
   */
  toNumber: (obj: any, defaultVal: number | null = null): number | null =>
    T.isNU(obj) ? defaultVal
      : typeof obj === 'number' ? obj as number
        : typeof obj === 'string' ? T.ifNaN(Number(obj), defaultVal) : defaultVal,


  toInt: (value:any, defaultVal = 0) => {
    if (typeof value == 'number') return value;
    if (typeof value != 'string') return defaultVal;
    if (T.isEmpty(value)) return defaultVal;
    value = T.removeComma(value);
    return !isNaN(value) ? parseInt(value) : T.isEmpty(defaultVal) ? 0 : defaultVal;
  },


  /**
   * 값이 비어있을때 defaultVal 를 반환
   * @param {string} str
   * @param {string} defaultStr
   * @returns {string}
   */
  ifBlank: (str, defaultStr) => T.isNotBlank(str) ? str : defaultStr,

  /**
   * 키워드가 안에 있나 확인
   * @param {string} str
   * @param {string} keyword
   * @returns {boolean}
   */
  contains: (str, keyword) => T.isNUAny(str, keyword) ? false : str.indexOf(keyword) >= 0,

  /**
   * string 안의 \n 을 &lt;br/&gt;로 바꾼다.
   * @param {string} str
   * @returns {string}
   */
  // newlineToBr: str => T.isEmpty(str) ? "" : ("" + str).replace(/\n/g, "<br/>\n"),
  newlineToBr: str => T.isEmpty(str) ? "" : ("" + str).replace(/\n/g, "<br/>\n"),

  /**
   * aliase newlineToBr
   * @deprecated
   */
  convertNewline: str => T.newlineToBr(str),

  split: (value, delimiter = ',') => {
    if (T.isNU(value)) return []
    const re = new RegExp("\\s*" + delimiter + "\\s*");
    return value.trim().split(re)
  },

  /**
   * 의미상 0 이면 빈값("")으로 변환<pre>
   * T.removeZero(0)   == ""
   * T.removeZero("0") == ""
   * T.removeZero("1") == "1"
   * T.removeZero(1)   == 1
   * </pre>
   * @param {string|number} value
   * @returns {string|number}
   */
  removeZero: value => T.isEmpty(value) || value == 0 ?  "" : value,


  /**
   * 안전한 replace all<pre>
   *   T.replaceAll(null)               === ""         // undefined blank 도 마찬가지
   *   T.replaceAll('111111', '1', '2') === '222222'   // 모든 1 를 2 로 바꾼다
   * @param str
   * @param targetString
   * @param replaceString
   */
  replaceAll: (str: string, targetString: string, replaceString: string) => {
    if (T.isBlank(str)) return ""
    return str.replace(new RegExp(targetString,"g"), replaceString)
  },

  /**
   * unmask('2020-01-__', '____-__-__') === '2020-01'
   */
  unmask: (str:string, mask:string):string => {
    if (T.isNU(str)) return str;
    if (str.length != mask.length) return str;
    let i=str.length
    for(; i> 0; i--) {
      if (str[i-1] !== mask[i-1]) break;
    }
    return str.substring(0,i);
  },

  /**
   * 숫자를 한글로 변환한다. https://github.com/wisedog/vue-number-to-kor/blob/master/src/filters/index.ts
   * @param amt Filter 값으로 넘어온 숫자
   * @param surfix "원"
   * @param priceMode true면 변환된 한글에 '일'이 붙는다. 예를 들어 '일십이만삼천원'과 같이 주로 금액을 표시할때 사용한다.
   *                  false라면 '일'이 생략된다. 다만 억과 같이 단독으로 사용했을때 어색한 숫자는 앞에 '일'을 붙인다.
   * @returns 한글로 변환된 숫자를 리턴한다. 만약 숫자가 아니거나, 범위를 초과하거나 기타 문제가 발생했을 경우 빈 문자열을 리턴한다.
   */
  numToKr: (amt: number | string, surfix: string = "원", priceMode = false): string => {
    if (T.isBlank(amt)) return ""
    const value = T.toInt(amt)

    if (value === 0) {
      return '영' + surfix;
    } else if (value === 1) {
      return '일' + surfix;
    }

    const han1 = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    const han2 = ['', '만', '억', '조', '경', '해'];
    const han3 = ['', '십', '백', '천'];

    if (!priceMode) {
      // 1만 들어올 경우 문제가 생긴다.
      if (value !== 1) {
        han1[1] = '';
      }
    }

    const numStr = value.toString();
    const size = numStr.length;

    if (size > 4 * han2.length) {
      return '';
    }
    let kor = '';
    let tmp: string[] = [];

    for (let i = 1; i < numStr.length + 1; i++) {
      const v1 = Number.parseInt(numStr.charAt(i - 1), 10);
      if (isNaN(v1)) {
        break;
      }
      if (v1 !== 0) {
        tmp.push(han1[v1]);
        tmp.push(han3[(size - i) % 4]);
      }

      if ((size - i) % 4 === 0 && tmp.length !== 0) {
        // 일억과 같이 일을 생략하면 어색한 숫자는 '일'을 붙여준다.
        kor += tmp.join('');
        if (tmp.length === 2 && v1 === 1 && !priceMode) {
          kor += '일';
        }

        tmp = [];
        kor += han2[(size - i) / 4];
      }
    }
    return kor + surfix;
  },

  /**
   * 경로에서 확장자만 뽑아낸다.
   * <pre>
   *   getFileExtOnly("  aa.gif  ")    === "gif"
   *   getFileExtOnly("aa/aa.gif")     === "gif"
   *   getFileExtOnly("aa/aa.gif.txt") === "txt"
   *   getFileExtOnly("aa/aa")         === ""
   *   getFileExtOnly(null)            === ""
   * </pre> */
  getFileExtOnly: (path: string): string => {
    if (!path) return ""

    let workPath = path.trim()
    let pos = workPath.lastIndexOf("/")
    if (pos >= 0) workPath = workPath.substring(pos + 1)

    pos = workPath.lastIndexOf("\\")
    if (pos >= 0) workPath = workPath.substring(pos + 1)

    pos = workPath.lastIndexOf(".")
    return (pos >= 0) ? workPath.substring(pos + 1) : ""
  },

  /**
   * byte 를 사람이 읽기 편하게
   * @param {number} bytes 입력
   * @param {number} dp 소숫점 몇자리 까지 보여줄지. 기본값 0
   * @param {boolean} bi (기본값 true) false로 설정하면 MiB 표기를 사용한다
   * @return {string}
   */
  byteToHumanSize: (bytes: number, dp: number = 0, bi: boolean = true): string => {
    const thresh = bi ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = bi
      ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  },

}

